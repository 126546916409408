// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-archive-page-tsx": () => import("./../../../src/components/ArchivePage.tsx" /* webpackChunkName: "component---src-components-archive-page-tsx" */),
  "component---src-components-content-page-tsx": () => import("./../../../src/components/ContentPage.tsx" /* webpackChunkName: "component---src-components-content-page-tsx" */),
  "component---src-components-post-page-tsx": () => import("./../../../src/components/PostPage.tsx" /* webpackChunkName: "component---src-components-post-page-tsx" */),
  "component---src-components-race-result-page-tsx": () => import("./../../../src/components/RaceResultPage.tsx" /* webpackChunkName: "component---src-components-race-result-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-bibliography-tsx": () => import("./../../../src/pages/bibliography.tsx" /* webpackChunkName: "component---src-pages-bibliography-tsx" */),
  "component---src-pages-branding-tsx": () => import("./../../../src/pages/branding.tsx" /* webpackChunkName: "component---src-pages-branding-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */)
}

